<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <!-- [S]결재관련 버튼 -->
                <!-- sysApprovalRequestId: 상세조회시 관련 결재요청일련번호 확인 -->
                <!-- param.isApprContent: 결재요청/결재처리 팝업의 component에 해당 페이지 호출시 true -->
                <!-- approvalStatusCd: 결재요청건에 대한 상태코드 -->
                <!-- param.approvalDocType: TODO(결재할문서에서 상세페이지호출시) -->
                <c-appr-btn 
                  ref="appr-btn"
                  name="safety-gear-request-appr-btn"
                  :editable="editable"
                  :approvalInfo="approvalInfo"
                  @beforeApprAction="saveDataAppr"
                  @callbackApprAction="approvalCallback"
                  @requestAfterAction="getDetail"
                />
                <c-btn
                  v-if="editable && updateMode && !data.approvalStatusCd"
                  label="삭제"
                  icon="remove"
                  @btnClicked="deleteData" />
                <c-btn
                  v-if="editable && !data.approvalStatusCd"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveInfo"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-datepicker
                  :required="true"
                  :editable="editable && !updateMode"
                  label="등록일"
                  name="dailyResultDt"
                  v-model="data.dailyResultDt"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-field
                  :required="true"
                  :editable="editable"
                  :data="data"
                  deptValue="deptCd"
                  type="dept_user"
                  label="작성부서 & 작성자"
                  name="userId"
                  v-model="data.userId">
                </c-field>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-plant :required="true" :editable="editable && !updateMode" type="edit" name="plantCd" v-model="data.plantCd" @datachange="getAdditives" />
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-table
            ref="grid"
            title="폐수 전량위탁 처리정보"
            tableId="grid"
            :columns="grid.columns"
            :data="grid.data"
            :usePaging="false"
            :hideBottom="true"
            :columnSetting="false"
            :isFullScreen="false"
            :filtering="false"
            :isExcelDown="false"
            :editable="editable"
            gridHeight="300px"
          >
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-table
            ref="grid1"
            title="원료 또는 첨가제 등의 사용량"
            tableId="grid1"
            :columns="grid1.columns"
            :data="data.waterEntrustResultAdditives"
            :usePaging="false"
            :hideBottom="true"
            :columnSetting="false"
            :filtering="false"
            :isExcelDown="false"
            :editable="editable"
            gridHeight="300px"
          >
          </c-table>
        </div>
      </div>
    </q-form>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'daily-all-cons-report-manage-detail',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          envWaterEntrustResultId: '',
          plantCd: null,
          dailyResultDt: '',
          approvalStatusCd: null,
        }
      },
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: true,
      listUrl: '',
      data: {
        plantCd: null,  // 사업장코드
        envWaterEntrustResultId: '',  // 폐수전량위탁운영일지 일련번호
        dailyResultDt: '',  // 작성일
        deptCd: '',  // 작성부서
        userId: '',  // 작성자
        oprStartDt: '',  // 가동시작시간
        oprEndDt: '',  // 가동종료시간
        useAmount: '',  // 용수사용량
        wasteAmount: '',  // 폐수발생량
        entrustAmount: '',  // 위탁량
        preAmount: '',  // 전일보관폐수량
        stoAmount: '',  // 보관폐수량
        entrustNo: '',  // 위탁수탁확인서일련번호
        entrustVendorName: '',  // 처리업소명
        remark: '',  // 기타 중요사항
        waterEntrustResultAdditives: [],
      },
      grid: {
        columns: [
          {
            name: 'oprTime',
            field: 'oprTime',
            label: '가동시간(시작 ~ 종료)',
            align: 'center',
            sortable: false,
            type: 'datetime',
            minuteStep: 10,
            range: true,
            style: 'width:120px',
          },
          {
            name: 'useAmount',
            field: 'useAmount',
            label: '용수사용량(L)',
            align: 'center',
            sortable: false,
            type: 'number',
            style: 'width:120px',
          },
          {
            name: 'wasteAmount',
            field: 'wasteAmount',
            label: '폐수발생량(L)',
            align: 'center',
            sortable: false,
            type: 'number',
            style: 'width:120px',
          },
          {
            label: '폐수 위탁처리',
            align: 'center',
            child: [
              {
                name: 'entrustAmount',
                field: 'entrustAmount',
                label: '위탁량(L)',
                align: 'center',
                sortable: false,
                type: 'number',
                style: 'width:120px',
              },
              {
                name: 'entrustNo',
                field: 'entrustNo',
                label: '위탁ㆍ수탁 확인서<br>일련번호',
                align: 'center',
                sortable: false,
                type: 'text',
                style: 'width:160px',
              },
              {
                name: 'entrustVendorName',
                field: 'entrustVendorName',
                label: '처리업소명',
                align: 'center',
                sortable: false,
                type: 'text',
                style: 'width:150px',
              },
            ]
          },
          {
            name: 'stoAmount',
            field: 'stoAmount',
            label: '보관폐수량(L)',
            align: 'center',
            sortable: false,
            type: 'number',
            style: 'width:120px',
          },
          {
            name: 'remark',
            field: 'remark',
            label: '기타 중요사항',
            align: 'center',
            sortable: false,
            type: 'text',
            style: 'width:200px',
          },
        ],
        data: [],
      },
      grid1: {
        columns: [
          {
            name: 'envWaterMstAdditiveName',
            field: 'envWaterMstAdditiveName',
            label: '원료/첨가제 명',
            align: 'center',
            sortable: false,
          },
          {
            name: 'useAmount',
            field: 'useAmount',
            label: '사용량(kg)',
            align: 'center',
            type: 'number',
            style: 'width:200px',
            sortable: false,
          },
        ],
        data: [],
      },
      saveUrl: transactionConfig.env.water.daily.result.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      isSave: false,
      updateMode: false,
      detailUrl: '',
      additiveUrl: '',
      isApproval: false,
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    approvalInfo() {
      return {
        sysApprovalRequestId: this.data.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.data.approvalStatusCd, 
        apprEditable: this.data.approvalStatusCd === null || this.data.approvalStatusCd == 'ASC0000001', // 결재버튼 활성화 기준
        param: this.data, // 결재 param
        approvalUrl: this.updateUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000015', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          envWaterEntrustResultId: this.popupParam.envWaterEntrustResultId,
          plantCd: this.popupParam.plantCd,
          isFullScreen: false,
        },
        approvalRequestName: '폐수전량위탁운영일지 ['+this.data.dailyResultDt+']', // 결재요청명 (문서 title)
        approvalConnId: this.popupParam.envWaterEntrustResultId, // 결재연결용 업무일련번호 (문서 key)
      }
    },
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.env.water.entrust.result.get.url;
      this.additiveUrl = selectConfig.env.water.entrust.additive.list.url;
      this.saveUrl = transactionConfig.env.water.entrust.result.insert.url;
      this.updateUrl = transactionConfig.env.water.entrust.result.update.url;
      this.deleteUrl = transactionConfig.env.water.entrust.result.delete.url;
      this.getDetail();
    },
    saveDataAppr() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '결재요청 하기 전 입력된 값을 저장합니다.\n\r 진행하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.data.regUserId = this.$store.getters.user.userId;
          this.data.chgUserId = this.$store.getters.user.userId;
          let promises = [
            {
              func: this.approvalValue
            },
          ];
          this.$comm.orderedPromise(promises);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    // [S] 결재관련 
    approvalCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
    approvalValue() {
      return new Promise(resolve => {
        this.isApproval = !this.isApproval
        resolve(true);
      });
    },
    getDetail() {
      if (this.popupParam.envWaterEntrustResultId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {envWaterEntrustResultId: this.popupParam.envWaterEntrustResultId, plantCd: this.popupParam.plantCd}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$set(_result.data, 'oprTime', [_result.data.oprStartDt, _result.data.oprEndDt])
          this.data = _result.data;
          this.updateMode = true;
          this.grid.data = [];
          this.grid1.data = [];
          this.grid.data.push(this.data);
          this.grid1.data = this.data.waterEntrustResultAdditives;
        },);
      } else {
        this.grid.data = [{
          plantCd: this.data.plantCd,  // 사업장코드
          envWaterEntrustResultId: '',  // 폐수전량위탁운영일지 일련번호
          dailyResultDt: this.$comm.getToday(),  // 작성일
          deptCd: this.$store.getters.user.deptCd,  // 작성부서
          userId: this.$store.getters.user.userId,  // 작성자
          oprStartDt: '',  // 가동시작시간
          oprEndDt: '',  // 가동종료시간
          useAmount: '',  // 용수사용량
          wasteAmount: '',  // 폐수발생량
          entrustAmount: '',  // 위탁량
          preAmount: '',  // 전일보관폐수량
          stoAmount: '',  // 보관폐수량
          entrustNo: '',  // 위탁수탁확인서일련번호
          entrustVendorName: '',  // 처리업소명
          remark: '',  // 기타 중요사항
        }]
        this.data.dailyResultDt = this.$comm.getToday();
        this.data.userId = this.$store.getters.user.userId;
        this.data.deptCd = this.$store.getters.user.deptCd;
        this.getAdditives(this.data.plantCd);
      }
    },
    getAdditives(_plantCd) {
      let plantCd = '';
      if (_plantCd.value) {
        plantCd = _plantCd.value;
      } else {
        plantCd = _plantCd;
      }
      this.$http.url = this.additiveUrl;
      this.$http.param = {plantCd: plantCd}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        _result.data = this.$_.map(_result.data, (item) => {
          return this.$_.extend(item, {
            regUserId: this.$store.getters.user.userId
          })
        })
        this.data.waterEntrustResultAdditives = _result.data;
      },);
    },
    saveInfo() {
      if (this.popupParam.envWaterEntrustResultId) {
        this.saveUrl = transactionConfig.env.water.entrust.result.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.env.water.entrust.result.insert.url;
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.data.plantCd = this.data.plantCd;  // 사업장코드
              this.data.envWaterEntrustResultId = this.data.envWaterEntrustResultId;  // 폐수전량위탁운영일지 일련번호
              this.data.dailyResultDt = this.data.dailyResultDt;  // 작성일
              this.data.deptCd = this.data.deptCd;  // 작성부서
              this.data.userId = this.data.userId;  // 작성자
              this.data.oprStartDt = this.grid.data[0].oprTime && this.grid.data[0].oprTime.length > 0 ?
                this.grid.data[0].oprTime[0] : '';  // 가동시작시간
              this.data.oprEndDt = this.grid.data[0].oprTime && this.grid.data[0].oprTime.length > 0 ?
                this.grid.data[0].oprTime[1] : '';  // 가동종료시간
              this.data.useAmount = this.grid.data[0].useAmount;  // 용수사용량
              this.data.wasteAmount = this.grid.data[0].wasteAmount;  // 폐수발생량
              this.data.entrustAmount = this.grid.data[0].entrustAmount;  // 위탁량
              this.data.preAmount = this.grid.data[0].preAmount;  // 전일보관폐수량
              this.data.stoAmount = this.grid.data[0].stoAmount;  // 보관폐수량
              this.data.entrustNo = this.grid.data[0].entrustNo;  // 위탁수탁확인서일련번호
              this.data.entrustVendorName = this.grid.data[0].entrustVendorName;  // 처리업소명
              this.data.remark = this.grid.data[0].remark;  // 기타 중요사항
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.popupParam.envWaterEntrustResultId = result.data.envWaterEntrustResultId;
      this.popupParam.plantCd = result.data.plantCd;
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '일지전체 내용이 삭제됩니다.\r\n삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.envWaterEntrustResultId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>